import { onceConsented } from "@telia/b2x-cookie-consent";
import { inject, show } from "@telia/b2x-giosg";
import { rule } from "@telia/b2x-url-segments";
import { w } from "../util";
import {
  GIOSG_ACCOUNT_ID_B2C,
  GIOSG_ACCOUNT_ID_B2B,
  GIOSG_OPEN_FROM_VIRTUAL_ASSISTANT_EVENT_NAME,
} from "../constants";

export const initGiosg = () => {
  const COOKIE_GROUP = "C0001";

  // Open Whisbi upon Ace AI TV Chat custom event.
  window.addEventListener(GIOSG_OPEN_FROM_VIRTUAL_ASSISTANT_EVENT_NAME, () => {
    show();
  });

  if (w) {
    const r = rule(new URL(w.location.href));

    if ((r.isPublic || r.pathStartsWith(["/mitt-telia/whisbi-support"])) && r.isB2C) {
      onceConsented(COOKIE_GROUP, "Enable Giosg in B2C").then(() => inject(GIOSG_ACCOUNT_ID_B2C));
    }

    if (r.isPublic && r.isB2B) {
      onceConsented(COOKIE_GROUP, "Enable Giosg in B2B, excluding MyBusiness").then(() =>
        inject(GIOSG_ACCOUNT_ID_B2B)
      );
    }
  }
};
