import { init } from "@telia/b2x-cookie-consent";
import {
  ONETRUST_ID_PRODUCTION,
  ONETRUST_ID_TEST,
  ONETRUST_SCRIPT_SRC_PRODUCTION,
  ONETRUST_SCRIPT_SRC_TEST,
  MT_APP_TEMP_UA,
} from "../constants";

import { rule } from "@telia/b2x-url-segments";
import { w } from "../util";

export const initOneTrust = () => {
  // "Embedded" page in Mitt Telia app, where the web view agent is set by Mitt Telia
  const isMittTeliaAppTempUA =
    w?.navigator && new RegExp(MT_APP_TEMP_UA).test(w.navigator.userAgent);

  if (w && !isMittTeliaAppTempUA) {
    const r = rule(new URL(w.location.href));
    const id = r.isProd ? ONETRUST_ID_PRODUCTION : ONETRUST_ID_TEST;
    const src = r.isProd ? ONETRUST_SCRIPT_SRC_PRODUCTION : ONETRUST_SCRIPT_SRC_TEST;

    init(id, src);
  }
};
