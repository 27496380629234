import { onceConsented } from "@telia/b2x-cookie-consent";
import { init } from "@telia/b2x-gtm";
import { GTM_CONTAINER_ID_TEST, GTM_TEST_PREVIEW, GTM_TEST_AUTH } from "../constants";

export const initGTM = () => {
  onceConsented("C0002", "Google Tag Manager for marketing").then(() => {
    const hostname = window?.location.hostname;
    const isTest = hostname.includes("test") || hostname.includes(".pr.");

    const previewConfig = isTest ? { preview: GTM_TEST_PREVIEW, auth: GTM_TEST_AUTH } : {};

    init({ containerId: GTM_CONTAINER_ID_TEST, ...previewConfig });
  });
};
